// Font Family
$poppins: "Poppins", 'Prompt', sans-serif;
$abrilfatface: "Abril Fatface", 'Prompt', cursive;
$cormorantgaramond: "Cormorant Garamond",'Prompt';
$greatvibes: "Great Vibes", 'Prompt', cursive;
$lobster: "Lobster", 'Prompt', cursive;
$josefin: "Josefin Sans", 'Prompt', sans-serif;
$Bonheur: "Bonheur Royale", 'Prompt', cursive;
$Lobster: "Lobster", 'Prompt', cursive;
$PT: "PT Sans Narrow", 'Prompt', sans-serif;
$sriravha: "Sriracha", 'Prompt', cursive;
$pr: 'Prompt', sans-serif;
$noto: 'Noto Serif Thai', 'Prompt', serif;

// text Colors ---------------

$white: #ffffff;
$black: #000000;
$grey: #f6f7f8;
$red: #f6f7f8;
$green: #f6f7f8;
$gold:#C9A885 ;
$golden:#EEDEBC;

// bg Colors ---------------

$bg-white: #ffffff;
$bg-black: #000000;
$bg-grey: #f6f6f8;
$bg-purple: #f0e0ff;
$bg-pink: #ffe0ee;

// Heading Color
$heading-color: #000000;

// Theme Color
$theme-color: #f8abaf;

// Responsive Variables
$xx-layout: "only screen and (min-width: 1366px) and (max-width: 1600px)";
$xl-layout: "only screen and (min-width: 1200px) and (max-width: 1365px)";
$lg-layout: "only screen and (min-width: 992px) and (max-width: 1199px)";
$md-layout: "only screen and (min-width: 768px) and (max-width: 991px)";
$xs-layout: "only screen and (max-width: 767px)";
$sm-layout: "only screen and (min-width: 576px) and (max-width: 767px)";
$xxs-layout: "only screen and (min-width: 320px) and (max-width: 479px)";
