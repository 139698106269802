/*---- 17. Sidebar style  ------*/
@import "./variables";
.sidebar-style {
  font-family: $pr;
  @media #{$lg-layout} {
    &.mr-30 {
      margin-right: 0;
    }
  }
  @media #{$md-layout} {
    margin-top: 30px;
    &.mr-30 {
      margin-right: 0;
    }
  }
  @media #{$xs-layout} {
    margin-top: 30px;
    &.mr-30 {
      margin-right: 0;
    }
  }
  @media #{$lg-layout} {
    &.ml-30 {
      margin-left: 0;
    }
  }
  @media #{$md-layout} {
    margin-top: 30px;
    &.ml-30 {
      margin-left: 0;
    }
  }
  @media #{$xs-layout} {
    margin-top: 30px;
    &.ml-30 {
      margin-left: 0;
    }
  }
}

.sidebar-widget {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: unset;
  }
  font-family: $pr !important;

  h4.pro-sidebar-title {
    font-size: 16px;
    font-weight: 500;
    font-family: $pr !important;

    margin: 0;

    color: rgba(0, 219, 99, 0.753);
    .addpro {
      font-size: 14px;
      font-weight: 500;
      line-height: 2;
      margin-top: 25px;
      padding: 15px 52px;
      text-transform: uppercase;
      color: #fff;
      border: none;
      background-color: #f8abaf;
    }
  }

  .sidebar-widget-list {
    .addpro {
      font-size: 14px;
      font-weight: 500;
      line-height: 2;
      margin-top: 5px;
      padding: 8px 52px;
      color: #fff;
      border: none;
      background-color: #8a8a8a;
    }
    font-family: $pr;
    ul {
      li {
        position: relative;

        align-items: center;
        font-family: $pr;

        padding: 0 0 15px;
        &:last-child {
          padding: 0 0 0;
        }
        a,
        button {
          position: relative;
          display: block;
          font-family: $pr;
          width: 100%;
          padding: 0;
          padding-left: 30px;
          text-align: left;
          text-transform: capitalize;
          border: none;
          background: none;
          opacity: 1;
          &.box-check-box {
            padding: 0.75rem 1.25rem;
            padding-left: 2.5rem;
            border: 1px solid #00000020 !important;
            border-bottom: 3px solid #000000 !important;
            background: none;
            h4 {
              padding: 0;
              margin: 0;
            }
            .checkmark {
              position: absolute;
              left: 1rem;
              width: 15px;
              height: 15px;
              top: 50%;
              transform: translateY(-50%);
              border: 2px solid #888888;
              border-radius: 3px;
              background-color: #fff;
              &::after {
                position: absolute;
                display: none;
                content: "";
                left: 1px;
                width: 10px;
                height: 10px;
                transform: rotate(45deg);
                border: solid white;
                border-width: 0 2px 2px 0;
                border-top-width: medium;
                border-right-width: medium;
                border-bottom-width: medium;
                border-left-width: medium;
              }
            }
          }
          &:hover,
          &:focus,
          &.active {
            border-bottom: 3px solid $theme-color !important;
            span {
              background-color: $theme-color;
            }
            & > .checkmark {
              border-color: $theme-color;
              background-color: $theme-color;
              &:after {
                display: block;
              }
            }
          }
        }
      }
    }

    &--blog {
      ul {
        li {
          .sidebar-widget-list-left {
            font-family: $pr;

            .checkmark {
              top: 5px;
            }
          }
        }
      }
    }
  }
}
.sidebar-widget-tag {
  font-family: $pr;

  ul {
    li {
      display: inline-block;

      margin: 0 10px 15px 0;
      @media #{$lg-layout} {
        margin: 0 5px 15px 0;
      }
      a,
      button {
        line-height: 1;

        display: inline-block;

        padding: 9px 17px 12px;

        text-transform: capitalize;

        color: #424242;
        border: none;
        border-radius: 50px;
        background: none;
        background-color: #f6f6f6;
        @media #{$lg-layout} {
          padding: 9px 13px 12px;
        }
        &:hover,
        &.active {
          color: #fff;
          background-color: $theme-color;
        }
      }
    }
  }
}

.pro-sidebar-search {
  .pro-sidebar-search-form {
    position: relative;
    input {
      font-size: 14px;

      height: 43px;
      padding: 2px 55px 2px 18px;

      color: #000;
      border: 1px solid #e6e6e6;
      background: transparent none repeat scroll 0 0;
    }
    button {
      font-size: 20px;

      position: absolute;
      top: 50%;
      right: 0;

      padding: 0 15px;

      cursor: pointer;
      transition: all 0.3s ease 0s;
      transform: translateY(-50%);

      color: #000;
      border-width: medium medium medium 1px;
      border-style: none none none solid;
      border-color: #a1a5aa;
      border-image: none;
      background: transparent none repeat scroll 0 0;
      &:hover {
        color: $theme-color;
      }
    }
  }
}

.single-sidebar-blog {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  margin: 0 0 20px;
  padding: 0 0 20px;

  border-bottom: 1px solid #dee0e4;
  &:last-child {
    border-bottom: none;
  }
  .sidebar-blog-img {
    flex: 0 0 70px;

    margin: 0 20px 0 0;
    @media #{$lg-layout} {
      margin: 0 8px 0 0;
    }
    img {
      width: 100%;
    }
  }
  .sidebar-blog-content {
    span {
      font-size: 12px;
      font-weight: 400;
      line-height: 1;

      display: block;

      letter-spacing: 1px;
      text-transform: uppercase;

      color: #333;
    }
    h4 {
      font-size: 14px;

      margin: 5px 0 0;
    }
  }
}
.box-check-box {
  position: relative;
  align-items: center;
  display: block;
  font-family: $pr;
  width: 100%;
  margin: 0 0 15px;
  text-align: left;
  text-transform: capitalize;
  opacity: 1;
  padding: 0.75rem 1.25rem;
  padding-left: 2.5rem;
  border: 1px solid #00000020 !important;
  border-bottom: 3px solid #000000 !important;
  background: none;
  h4 {
    padding: 0;
    margin: 0;
  }
  .checkmark {
    position: absolute;
    left: 1rem;
    width: 15px;
    height: 15px;
    top: 50%;
    transform: translateY(-50%);
    border: 2px solid #888888;
    border-radius: 3px;
    background-color: #fff;
    &::after {
      position: absolute;
      display: none;
      content: "";
      left: 1px;
      width: 10px;
      height: 10px;
      transform: rotate(45deg);
      border: solid white;
      border-width: 0 2px 2px 0;
      border-top-width: medium;
      border-right-width: medium;
      border-bottom-width: medium;
      border-left-width: medium;
    }
  }

  &.active {
    border-bottom: 3px solid #f8abaf !important;
    span {
      background-color: #f8abaf;
    }
    & > .checkmark {
      border-color: #f8abaf;
      background-color: #f8abaf;
      &:after {
        display: block;
      }
    }
  }
  &:last-child {
    margin: 0 0 0;
  }
}
@supports (-webkit-touch-callout: none) {
  // Safari-only CSS rules go here
  .box-check-box {
    -webkit-border-radius: 3px;
    -webkit-box-shadow: 2px 2px 2px #888888;
  }
  .checkmark {
    -webkit-border-radius: 3px;
  }
}
@supports (-webkit-appearance: none) {
  .box-check-box {
    & > .checkmark {
      border: 2px solid #888888;
      -webkit-appearance: none;
      &::after {
        border-width: 0 2px 2px 0;
        border-top-width: medium;
        border-right-width: medium;
        border-bottom-width: medium;
        border-left-width: medium;
        -webkit-transform: rotate(45deg);
      }
    }
  }
}
