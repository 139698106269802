@import "~bootstrap/scss/bootstrap.scss";
@import "~swiper/css/swiper.css";
@import "~animate.css/animate.min.css";
@import "~lightgallery.js/dist/css/lightgallery.css";
@import "~react-modal-video/scss/modal-video.scss";
@import "../css/icons.min.css";

@import url("https://fonts.googleapis.com/css2?family=Bonheur+Royale&family=Lobster&family=PT+Sans+Narrow&family=Sriracha&display=swap");

@import "variables";
@import "helper";
@import "common";
@import "preloader";
@import "header";
@import "slider";
@import "support";
@import "section-title";
@import "product";
@import "blog";
@import "footer";
@import "banner";
@import "suscribe";
@import "about";
@import "image-slider";
@import "testimonial";
@import "brand-logo";
@import "timers";
@import "breadcrumb";
@import "sidebar";
@import "shop";
@import "product-details";
@import "cart";
@import "compare";
@import "checkout";
@import "contact";
@import "blog-details";
@import "login-register";
@import "my-account";
@import "404";
@import "video-popup";


img {
  pointer-events: none;
}

.card {
  border: 1px solid #f8abaf;
}

.card-header {
  background-color: #f8abaf;
  border-bottom: 1px solid #f8abaf;

  .btn-link {
    color: #fff;
  }
}

.max-h-c {
  max-height: max-content;
  min-height: max-content;
  height: max-content;
}

.mh-70 {
  max-height: 20em;

  @media #{$lg-layout} {
    max-height: 20em;
  }

  @media #{$md-layout} {
    max-height: 10em;
  }

  @media #{$xs-layout} {
    max-height: 7em;
  }
}

.h-m-100 {
  min-height: 100%;
}

.btn-check-box {
  text-align: start;
}

.radio-box {
  display: block;
}

.btn-primary {
  background-color: #f8abaf;
  border-color: #f8abaf;
  box-shadow: 0 0 0 3px #f8abaf;
}

.btn-primary:hover {
  background-color: #f8abaf !important;
  border-color: #f8abaf !important;
  box-shadow: 0 0 0 3px #f8abaf;
}

.btn-primary:focus {
  background-color: #f8abaf !important;
  border-color: #f8abaf !important;
  box-shadow: 0 0 0 3px #f8abaf;
}

.btn-primary:active {
  background-color: #f8abaf !important;
  border-color: #f8abaf !important;
  box-shadow: 0 0 0 3px #f8abaf;
}

.swal2-styled.swal2-confirm:focus {
  background-color: #f8abaf !important;
  border-color: #f8abaf !important;
  box-shadow: 0 0 0 3px #f8abaf;
}

.swal2-styled.swal2-confirm {
  background-color: #f8abaf !important;
  border-color: #f8abaf !important;
  box-shadow: 0 0 0 3px #f8abaf;
}

// .query {
button.btn-check-box {
  position: relative;
  display: block;
  font-family: "Prompt", sans-serif;
  padding: 0;
  padding-left: 40px !important;
  text-align: left;
  text-transform: capitalize;
  border: none;
  background: none;
  margin-right: 1.25rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;

  .checkmark {
    position: absolute;
    left: 0;
    width: 30px;
    height: 30px;
    border: 2px solid #888888;
    border-radius: 3px;
    top: 50%;
    transform: translate(0%, -50%);
    background-color: #fff;

    img {
      width: 100%;
      display: none;
    }

    &::after {
      left: 10px;
      top: 6px;
      width: 7px;
      height: 12px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}

button.btn-check-box.checkbox:hover>.checkmark,
button.btn-check-box.active>.checkmark,
.btn.btn-check-box.checkbox:hover>.checkmark,
.btn.btn-check-box.active>.checkmark {
  background-color: #f8abaf;

  img {
    display: block;
  }
}

button.other {
  display: flex !important;
  align-items: center;

  div {
    width: -webkit-fill-available;
    max-width: max-content;
    margin-right: 20px;
  }
}

label.other {
  display: flex !important;
  align-items: center;

  div {
    width: -webkit-fill-available;
    max-width: max-content;
    margin-right: 20px;
  }
}

.btn.btn-check-box {
  position: relative;
  font-family: "Prompt", sans-serif;
  padding: 0;
  padding-left: 35px !important;
  text-align: left;
  text-transform: capitalize;
  border: none;
  background: none;
  margin-right: 1.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;

  .checkmark {
    position: absolute;
    left: 0;
    width: 30px;
    height: 30px;
    border: 2px solid #888888;
    border-radius: 3px;
    top: 50%;
    transform: translate(0%, -50%);
    background-color: #fff;

    img {
      width: 100%;
      display: none;
    }

    &::after {
      left: 10px;
      top: 6px;
      width: 7px;
      height: 12px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}

.product-img {
  .lazy-load-image-background.lazy-load-image-loaded {
    display: inline-block;
    padding-top: 100%;

    img.default-img {
      width: 100%;
      vertical-align: top;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
    }
  }
}

html {
  scroll-behavior: smooth;
}